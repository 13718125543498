<template>
  <div class="home">
    <!-- 头部 -->
    <div class="home_header">
      <img src="../../assets/images/img1/headerIcon.png" alt="" />
      <span>制度体系智能平台</span>
    </div>
    <!-- 退出 -->
    <div class="home_Exit" @click="loginUp"></div>
    <!-- //因为<router-view>标签页面嵌套在home页面下，所以无论跳到哪里都会到home -->
    <div class="topTab">
      <div class="cascader">
        <div @click="clickCascaderBtn" class="title">
          <img src="../../assets//images/img1/pageTab2.png" />
          <span>审核中心</span>
          <i
            style="margin-left: 0.2vw; color: #0c86e4"
            :class="[
              showCascader ? 'el-icon-caret-top' : 'el-icon-caret-bottom',
            ]"
          ></i>
        </div>
        <i v-if="showCascader" class="el-icon-caret-top cascader-icon"></i>
        <el-cascader-panel
          :options="options"
          v-if="showCascader"
          @change="handleCascader"
        ></el-cascader-panel>
      </div>
      <div @click="toControlPage">
        <img src="../../assets//images//img1//pageTab4.png" />
        <span>安全中心</span>
      </div>
      <div
        @click="
          $router.push('./home?fromPagePath=Lean&leftIndex=1&title=学习中心')
        "
      >
        <img src="../../assets//images//img1//pageTab5.png" />
        <span>学习中心</span>
      </div>
      <div
        @click="
          $router.push(
            './home?fromPagePath=ControlPage&leftIndex=3&title=控制中心'
          )
        "
      >
        <img src="../../assets//images//img1//pageTab3.png" />
        <span>控制中心</span>
      </div>
      <div @click="handleRouter('档案中心')">
        <img src="../../assets/images/img1//pageTab1.png" />
        <span>档案中心</span>
      </div>
      <div @click="handleRouter('公司部门设置')">
        <img src="../../assets/images//img1/pageTab7.png" />
        <span>设置</span>
      </div>
    </div>
    <!-- 内容 -->
    <div class="home_content">
      <!-- 左边内容 -->
      <div class="home_content_left">
        <div class="home_content_left_top">
          <div class="one">
            <div class="onediv">
              <img class="img1" :src="user.userPic" />
              <div>
                <p>{{ user.companyName }}</p>
                <p><span>所在部门：</span>{{ user.deptmentName }}</p>
              </div>
            </div>
            <div class="user">
              {{ user.userName }}
              <img src="../../assets/images//img1/page4.png" />
            </div>
          </div>
          <div class="tow">
            <div @click="toAssignStudyPage('文件-学习')">
              <p>待学习-文件</p>
              {{ assignByUserData.studyCount }}
            </div>
            <div @click="toAssignStudyPage('文件-考核')">
              <p>待考核-文件</p>
              {{ assignByUserData.testCount }}
            </div>
            <div @click="toAssignStudyPage('专题-学习')">
              <p>待学习-专题</p>
              {{ assignByUserData.themeStudyCount }}
            </div>
            <div @click="toAssignStudyPage('专题-考核')">
              <p>待考核-专题</p>
              {{ assignByUserData.themeTestCount }}
            </div>
          </div>
        </div>
        <div class="home_content_left_center">
          <div class="topBox">
            <div class="titleBox titleBox1">
              <span>内审总体情况汇总表</span>
            </div>
            <div style="display: none">
              <el-dropdown class="smallBox">
                <span>
                  全部<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>黄金糕</el-dropdown-item>
                  <el-dropdown-item>狮子头</el-dropdown-item>
                  <el-dropdown-item>螺蛳粉</el-dropdown-item>
                  <el-dropdown-item disabled>双皮奶</el-dropdown-item>
                  <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="echart-box">
            <span>问题点数</span>
            <div id="category" style="width: 22vw; height: 12vw"></div>
          </div>
          <div class="info" v-if="form.dept">
            <p>今年vs去年</p>
            <div>
              <div>
                <p>审核部门/生产作业场所 {{ form.dept.oneYearCount }}</p>
                <p>查阅文件和记录(分数) {{ form.file.oneYearCount }}</p>
                <p>轻微不符合(项数) {{ roundCount.unqualifiedCount }}</p>
                <p>建议项(项数) {{ roundCount.improveCount }}</p>
              </div>
              <div>
                <p>岗位(个数) {{ form.post.oneYearCount }}</p>
                <p>累计检查(项数) {{ form.audit.oneYearCount }}</p>
                <p>纠正项(项数) {{ roundCount.correctCount }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 中间内容 -->
      <div class="home_content_mid">
        <div class="topBox">
          <div class="titleBox"><span>问题点在各部门的分布情况</span></div>
          <div>
            <el-dropdown class="smallBox" @command="handleDropdown">
              <span>
                {{ year }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="new Date().getFullYear() - 2"
                  >{{ new Date().getFullYear() - 2 }}年</el-dropdown-item
                >
                <el-dropdown-item :command="new Date().getFullYear() - 1">
                  {{ new Date().getFullYear() - 1 }}年</el-dropdown-item
                >
                <el-dropdown-item :command="new Date().getFullYear()"
                  >{{ new Date().getFullYear() }}年</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="map-box">
          <div
            :class="[
              item.className,
              roundIndex == index ? 'activePicColor' : item.classPicColor,
              item.classWidth,
            ]"
            v-for="(item, index) in roundList"
            :key="index"
            @click="clickRoundItem(item, index)"
          >
            <span>{{ item.name }}</span>
            <span>{{ item.number }}</span>
          </div>
        </div>
        <div class="info">
          <p>{{ roundCount.name }}</p>
          <div>
            <div>
              <p>轻微不符合</p>
              <p>
                {{
                  roundCount.unqualifiedCount ? roundCount.unqualifiedCount : 0
                }}
              </p>
            </div>
            <div>
              <p>纠正项</p>
              <p>{{ roundCount.correctCount ? roundCount.correctCount : 0 }}</p>
            </div>
            <div>
              <p>建议项</p>
              <p>{{ roundCount.improveCount ? roundCount.improveCount : 0 }}</p>
            </div>
            <div>
              <p>合计</p>
              <p>{{ roundCount.totalCount ? roundCount.totalCount : 0 }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 右边内容 -->
      <div class="home_content_right">
        <div class="topBox">
          <div class="titleBox"><span>近三年各部门问题点的分布情况</span></div>
          <div style="display: none">
            <el-dropdown class="smallBox">
              <span>
                全部<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>黄金糕</el-dropdown-item>
                <el-dropdown-item>狮子头</el-dropdown-item>
                <el-dropdown-item>螺蛳粉</el-dropdown-item>
                <el-dropdown-item disabled>双皮奶</el-dropdown-item>
                <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div id="echart" style="height: 13vw"></div>
        <div class="echart-table">
          <el-table
            :data="echartTable"
            style="width: 100%"
            height="240"
            :header-cell-style="{ background: '#073C67', color: '#A6DFFD' }"
          >
            <el-table-column prop="compName" label="公司" show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              width="80"
              prop="threeYearFailCount"
              :label="new Date().getFullYear() - 2 + '年'"
            >
            </el-table-column>
            <el-table-column
              width="80"
              prop="twoYearFailCount"
              :label="new Date().getFullYear() - 1 + '年'"
            >
            </el-table-column>
            <el-table-column
              width="80"
              prop="oneYearFailCount"
              :label="new Date().getFullYear() + '年'"
            >
            </el-table-column>
          </el-table>
          <div class="amount-to">
            <span>{{ echartCound.compName }}</span>
            <span>{{ echartCound.threeYearFailCount }}</span>
            <span>{{ echartCound.twoYearFailCount }}</span>
            <span>{{ echartCound.oneYearFailCount }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import { get } from '../../api/http'
export default {
  data() {
    return {
      user: {
        companyName: '',
        userPic: '',
        userName: '',
        deptmentName: '',
      },
      showCascader: false,
      options: [
        {
          value: '1',
          label: '年度审核',
          children: [
            {
              value: '0',
              label: '股份公司年度审核',
            },
            {
              value: '1',
              label: '子公司年度审核',
            },
          ],
        },
        {
          value: '2',
          label: '季度审核',
          children: [
            {
              value: '2',
              label: '股份公司季度审核',
            },
            {
              value: '3',
              label: '子公司季审核',
            },
          ],
        },
        {
          value: '3',
          label: '自查自纠',
        },
        {
          value: '4',
          label: '7S检查',
          children: [
            {
              value: '1',
              label: '自查7s',
            },
            {
              value: '0',
              label: '审核7s',
            },
          ],
        },
        {
          value: '5',
          label: '安全考核细则',
        },
        // {
        //   value: '6',
        //   label: '风控审核',
        // },
      ],
      value: [],
      roundIndex: null,
      year: '',
      roundCount: {
        compName: '',
        unqualifiedCount: 0,
        correctCount: 0,
        improveCount: 0,
        totalCount: 0,
      },
      roundList: [
        {
          className: 'round1',
          classPicColor: 'roundY',
          classWidth: 'width4-5',
          name: '佛水公司',
          number: 0,
        },
        {
          className: 'round2',
          classPicColor: 'roundG',
          classWidth: 'width4-5',
          name: '金沙公司',
          number: 0,
        },
        {
          className: 'round3',
          classPicColor: 'roundB',
          classWidth: 'width5-7',
          name: '高明公司',
          number: 0,
        },
        {
          className: 'round4',
          classPicColor: 'roundY',
          classWidth: 'width5',
          name: '三水公司',
          number: 0,
        },
        {
          className: 'round5',
          classPicColor: 'roundB',
          classWidth: 'width5-7',
          name: '行政管理部',
          number: 0,
        },
        {
          className: 'round6',
          classPicColor: 'roundY',
          classWidth: 'width4-5',
          name: '佛水建设',
          number: 0,
        },
        {
          className: 'round7',
          classPicColor: 'roundB',
          classWidth: 'width5',
          name: '运营管理部',
          number: 0,
        },
        {
          className: 'round8',
          classPicColor: 'roundG',
          classWidth: 'width7',
          name: '排水事业部',
          number: 0,
        },
        {
          className: 'round9',
          classPicColor: 'roundB',
          classWidth: 'width5',
          name: '禅城公司',
          number: 0,
        },
        {
          className: 'round10',
          classPicColor: 'roundB',
          classWidth: 'width5-7',
          name: '投资发展部',
          number: 0,
        },
        {
          className: 'round11',
          classPicColor: 'roundG',
          classWidth: 'width4-5',
          name: '三水汇通',
          number: 0,
        },
        {
          className: 'round12',
          classPicColor: 'roundG',
          classWidth: 'width5',
          name: '绿能',
          number: 0,
        },
        {
          className: 'round13',
          classPicColor: 'roundG',
          classWidth: 'width4-5',
          name: '西江供水',
          number: 0,
        },
        {
          className: 'round14',
          classPicColor: 'roundY',
          classWidth: 'width4-5',
          name: '济通公司',
          number: 0,
        },
      ],
      echartTable: [],
      echartCound: {},
      assignByUserData: {},
      top: 0,
      form: {},
    }
  },

  mounted() {
    this.handleUser()
    this.getCategoryData()
    this.handleDropdown(new Date().getFullYear())
    this.getEchartData()
    this.getAssignByUser()
  },
  methods: {
    handleRouter(name) {
      var roleName = JSON.parse(sessionStorage.getItem('userInfo')).roleName
      var path = ''
      var isGo = true
      if (name == '公司部门设置') {
        path = './home?fromPagePath=Company_Dept&title=公司与部门设置'
        if (!new RegExp('股份公司管理员').test(roleName)) {
          isGo = false
        }
      }
      if (name == '档案中心') {
        path =
          './home?fromPagePath=archives-manage&leftIndex=2&title=档案管理&fromQuery=0'
        if (
          !new RegExp('公司管理员').test(roleName) &&
          !new RegExp('股份公司安全管理员').test(roleName) &&
          !new RegExp('公司安全管理员').test(roleName)
        ) {
          isGo = false
        }
      }
      if (!isGo) {
        this.$message({
          type: 'warning',
          message: '抱歉，没有权限',
        })
        return
      }
      this.$router.push(path)
    },
    handleUser() {
      var user = JSON.parse(sessionStorage.getItem('userInfo'))
      this.user.userName = user.userName
      this.$store.state.companyUserList.map((i) => {
        if (user.upCompanyId == i.id) {
          this.user.companyName = i.companyName
          this.getDeptList(i.id, user)
        }
      })
      get('/api/UserImage?UserId=' + user.id).then((resp) => {
        if (resp.code == 200) {
          if (resp.value == null) {
            this.user.userPic = require('../../assets/images/img1/header.png')
            return
          }
          this.user.userPic = resp.value.imagesAddr
        }
      })
    },
    getAssignByUser() {
      get('/api/HomePageData/GetAssignByUser').then((res) => {
        if (res.code == 200) {
          this.assignByUserData = res.data
        }
      })
    },
    getDeptList(e, user) {
      get('/api/Department/GetNoTree?CompId=' + e).then((res) => {
        res.data.map((i) => {
          if (i.id == user.upDeptmentId) {
            this.user.deptmentName = i.deptmentName
          }
        })
      })
    },
    loginUp() {
      this.$confirm('您确定要退出?').then(() => {
        sessionStorage.clear()
        this.$message({
          type: 'success',
          message: '退出成功',
        })
        this.$router.push('./login')
      })
    },
    clickCascaderBtn() {
      this.showCascader = !this.showCascader
    },
    async handleCascader(index) {
      if (index[0] == '3') {
        this.$router.push(
          './home?fromPagePath=QuestionPoint&leftIndex=null&title=自查自纠'
        )
        return
      }
      if (index[0] == '4') {
        // this.$router.push('/Examine_work?fromQuery=' + index[1])
         this.$router.push(
          './home?fromPagePath=Examine_work?fromQuery=' + index[1] + '&title=7S检查'
        )
        return
      }
      if (index[0] == '5') {
        this.$router.push(
          './home?fromPagePath=ExamineRules&leftIndex=null&title=安全考核细则'
        )
        return
      }
      if(index[0] == '6'){
        this.$router.push(
          './home?fromPagePath=risk&leftIndex=null&title=风控审核'
        )
        return
      }
      //审核中心
      var isGoMixingPage = true
      if (index[1] == 0) {
        //进年度审核需判断
        await get('/api/MixAuditHead/GetIsShow')
          .then((resp) => {
            if (resp.code == 200) {
              isGoMixingPage = resp.data //根据控制中心时间段控制入口
            } else {
              this.$message.error(resp.message)
            }
          })
          .catch(() => {
            this.$message.error('信息出错，请稍后再试！')
          })
      }
      if (!isGoMixingPage) {
        this.$message({
          type: 'warning',
          message: '抱歉，不在年度股份公司范围之内',
        })
        return
      }
      this.$router.push(
        './home?fromPagePath=mixing&leftIndex=0&title=审核中心&fromQuery=' +
          index[1]
      )
    },
    toControlPage() {
      var user = JSON.parse(sessionStorage.getItem('userInfo'))
      window.open(
        'http://10.0.1.76:5700/#/control?userno=' +
          user.userNo +
          '&username=' +
          user.userName
      )
    },
    toAssignStudyPage(e) {
      this.$router.push(
        './home?title=指派' +
          (e.split(',')[0] == '文件' ? '学习' : '专题') +
          '&fromPagePath=assignStudy&fromQuery=' +
          e
      )
    },
    clickRoundItem(e, i) {
      this.roundIndex = i
      this.roundCount = e
    },
    handleDropdown(e, data) {
      this.year = e
      get('/api/HomePageData/GetQuesByYear?Year=' + e)
        .then((res) => {
          if (res.code == 200) {
            this.roundList.map((e, endex) => {
              e.number = 0
              res.data.map((i, index) => {
                if (i.compName == '合计') {
                  this.roundCount = i
                  this.roundCount.name = '合计'
                  return
                }
                if (endex == index) {
                  e.name = i.compName
                  e.number = i.totalCount
                  e.unqualifiedCount = i.unqualifiedCount
                  e.correctCount = i.correctCount
                  e.improveCount = i.improveCount
                  e.totalCount = i.totalCount
                }
              })
            })
            if (!data) return
            this.$message({
              type: 'success',
              message: this.year + '查询成功',
            })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$message.error('查询失败')
        })
    },
    getCategoryData() {
      get('/api/HomePageData/GetPastThreeYearAudit').then((res) => {
        if (res.code == 200) {
          this.getCategory(res.data)
          this.form = res.data
        }
      })
    },
    getCategory(data) {
      var chartDom = document.getElementById('category')
      var myChart = echarts.init(chartDom)
      var year = new Date().getFullYear()
      var option = {
        legend: {
          right: 0,
          itemHeight: 6,
          icon: 'circle',
          textStyle: {
            color: 'white',
            padding: [0, 0, 0, -8],
          },
        },
        grid: {
          left: '1%', //默认10%
          right: '1%', //默认10%
          bottom: '5%', //默认60
          top: '20%',
          containLabel: true,
          //grid区域是否包含坐标轴的刻度标签
        },
        tooltip: {},
        dataset: {
          source: [
            ['product', year - 2 + '', year - 1 + '', year + ''],
            [
              '累计检查',
              data.audit.threeYearCount,
              data.audit.twoYearCount,
              data.audit.oneYearCount,
            ],
            [
              '部门个数',
              data.dept.threeYearCount,
              data.dept.twoYearCount,
              data.dept.oneYearCount,
            ],
            [
              '文件个数',
              data.file.threeYearCount,
              data.file.twoYearCount,
              data.file.oneYearCount,
            ],
            [
              '岗位个数',
              data.post.threeYearCount,
              data.post.twoYearCount,
              data.post.oneYearCount,
            ],
          ],
        },
        xAxis: [
          {
            type: 'category',

            axisLabel: {
              show: true,
              textStyle: {
                color: '#ABEAFF',
              },
            },
          },
        ],
        yAxis: {
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff',
            },
          },
        },
        series: [
          {
            type: 'bar',
            barWidth: 10,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2ED4ED' },
                  { offset: 1, color: '#3d7d91d5' },
                ]),
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
          {
            type: 'bar',
            barWidth: 10,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#087CF1' },
                  { offset: 1, color: '#133a7dcb' },
                ]),
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
          {
            type: 'bar',
            barWidth: 10,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#E39C32' },
                  { offset: 1, color: '#a06429d9' },
                ]),
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
        ],
      }
      option && myChart.setOption(option)
    },
    getEchartData() {
      get('/api/HomePageData/GetPastThreeYearQues').then((res) => {
        if (res.code == 200) {
          this.echartTable = []
          this.echartCound = {}
          this.getEchart(res.data)
        }
      })
    },
    getEchart(data) {
      var chartDom = document.getElementById('echart')
      var myChart = echarts.init(chartDom)
      var year = new Date().getFullYear()
      var xAxisData = []
      var threeYearData = []
      var towYearData = []
      var oneYearData = []
      data.map((e) => {
        if (e.compName == '合计') {
          this.echartCound = e
          return
        }
        this.echartTable.push(e)
        xAxisData.push(e.compName)
        threeYearData.push(e.threeYearFailCount)
        towYearData.push(e.twoYearFailCount)
        oneYearData.push(e.oneYearFailCount)
      })
      var option = {
        color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
        title: {
          // text: 'Gradient Stacked Area Chart',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          right: 20,
          top: 20,
          itemHeight: 6,
          icon: 'circle',
          textStyle: {
            color: 'white',
            padding: [0, 0, 0, -8],
          },
          data: [year - 2 + '', year - 1 + '', year + ''],
        },
        grid: {
          left: '5%', //默认10%
          right: '5%', //默认10%
          bottom: '5%', //默认60
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: xAxisData,
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: year - 2,
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 2, //线条
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(128, 255, 165)',
                },
                {
                  offset: 1,
                  color: 'rgb(1, 191, 236)',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: threeYearData,
          },
          {
            name: year - 1,
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 2, //线条
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(0, 221, 255)',
                },
                {
                  offset: 1,
                  color: 'rgb(77, 119, 255)',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: towYearData,
          },
          {
            name: year,
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 1, //线条
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(55, 162, 255)',
                },
                {
                  offset: 1,
                  color: 'rgb(116, 21, 219)',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: oneYearData,
          },
        ],
      }
      option && myChart.setOption(option)
    },
  },
}
</script>
<style lang="less" scoped>
.home {
  width: 100vw;
  height: 100vh;
  background: url('../../assets/images/img1/background.png') no-repeat;
  background-size: 100vw 100vh;
}

.home_header {
  width: 100%;
  height: 11vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../../assets/images/img1/header-background.png') no-repeat;
  background-size: 100% 11vh;
  background-position: 0;
}

.home_header img {
  width: 2.8vw;
  margin-left: -5vw;
}

.toback {
  margin-left: 0vw;
  margin-top: 1vh;
  transform: scale(0.8);
}

.home_header span {
  font-family: PingFang SC;
  font-weight: bold;
  color: #00e4ff;
  line-height: 30px;
  font-size: 1.6vw;
  margin-left: 0.5vw;
}

.home_Exit {
  position: absolute;
  top: 14px;
  right: 0;
  width: 7.6vw;
  height: 8vh;
  background: url('../../assets/images/img1/page1.png') no-repeat;
  background-size: 7.6vw 8vh;
}

.home_Exit div {
  color: #00e4ff;
  font-size: 1vw;
  margin-left: 3vw;
  margin-top: 2vh;
}

.home_Exit p {
  position: absolute;
  right: 6.5vw;
  top: 2vh;
  color: #00e4ff;
  font-size: 1vw;
}

.home_content_mid_img {
  width: 100%;
  height: 85%;
  position: absolute;
  top: 15%;
}

.home_content {
  width: 95%;
  margin-left: 2.4vw;
}

.home_content_left {
  width: 25vw;
  padding-top: 0.3vw;
  float: left;
}
.home_content_left_top {
  width: 25vw;
  height: 20vh;
  margin-bottom: 0.5vw;
  color: white;
  background: url('../../assets/images/img1/page3.png') no-repeat;
}

.home_content_left_top .one {
  padding-top: 1vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.home_content_left_top .onediv {
  display: flex;
  align-items: center;
}

.home_content_left_top .img1 {
  width: 3vw;
  height: 6.3vh;
  border-radius: 50px;
  margin-right: 10px;
}

.home_content_left_top .user {
  display: flex;
  align-items: center;
}

.home_content_left_top .user img {
  margin-left: 5px;
  width: 6px;
}

.home_content_left_top .tow {
  display: flex;
  justify-content: space-around;
  margin-top: 1vw;
  padding: 0 2vw;
}

.home_content_left_top .tow p {
  margin-bottom: 0.2vw;
  cursor: pointer;
}
.home_content_left_center,
.home_content_right {
  width: 25vw;
  background: url('../../assets/images/img1/page5.png') no-repeat;
  color: white;
}
.home_content_left_center {
  height: 54vh;
  .info {
    color: #aacada;
    & > p {
      display: inline-block;
      color: #d4f2f8;
      margin-left: 3vw;
      color: white;
      font-weight: bold;
      // background: url('../../assets/images/img1/textborder.png');
      background-size: 100% 100%;
    }
    & > div {
      display: flex;
      > div {
        margin-left: 3vw;
        > p {
          line-height: 1.5vw;
        }
      }
    }
  }
}

.topBox {
  padding: 1vw 1.5vw 0 1.5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleBox {
  height: 4vh;
  line-height: 4vh;
  padding-left: 5px;
  background-image: url('../../assets/images/img1/page8.png');
  background-size: 100% 100%;
  color: white;
}
.titleBox1 {
  width: 8vw;
}

.smallBox {
  color: #0e8bff;
  text-align: center;
  line-height: 3vh;
  width: 4vw;
  height: 3vh;
  background-image: url('../../assets/images/img1/page9.png');
  background-size: 4vw 3vh;
  display: inline-block;
  z-index: 999;
}

.home_content_mid {
  width: 45vw;
  height: 73.4vh;
  float: left;
  margin-top: 0.8vh;
  position: relative;
}
.home_content_mid .info {
  width: 100%;
  position: absolute;
  bottom: 0;
  & > p {
    display: inline-block;
    margin-bottom: 1vw;
    // color: white;
    margin-left: 3vw;
    font-size: 1vw;
    color: white;
    font-weight: bold;
    // background: url('../../assets/images/img1/textborder.png');
    background-size: 100% 100%;
  }
  & > div {
    display: flex;
    justify-content: space-around;
    & p:nth-of-type(1) {
      color: white;
      font-size: 1vw;
    }
    & p:nth-of-type(2) {
      color: #f3c903;
      font-size: 1vw;
    }
  }
}
.home_content_mid .titleBox {
  width: 11vw;
  background-size: 11vw 4vh;
}

.home_content_mid .smallBox:nth-of-type(1) {
  width: 8vw;
  background-size: 8vw 3vh;
  margin-right: 0.8vw;
}

.home_content_mid .smallBox .span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.8vw;
}

.home_content_mid .span .el-icon--right {
  line-height: normal !important;
}

.home_content_right {
  float: right;
  width: 25vw;
  height: 75vh;
  margin-top: 1vh;
}
.home_content_right .titleBox {
  width: 12vw;
}
.home_content_right .right1,
.home_content_right .right2 {
  width: 24.7vw !important;
}

.home_content_right .right1 {
  background-size: 24.7vw 20vh !important;
}

.home_content_right .right2 {
  background-size: 24.7vw 26.5vh !important;
}
.home_content_left_top,
.home_content_left_center,
.home_content_right {
  background-size: 100% 100vh;
  background-position: 0;
}
.map-box {
  width: 46.2vw;
  height: 80%;
  position: relative;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #183e66;
    font-weight: bold;
    position: absolute;
  }
  .roundY {
    background: url('../../assets/images/img1/round4.png') no-repeat;
    background-size: 100% 100%;
  }
  .roundG {
    background: url('../../assets/images/img1/round1.png') no-repeat;
    background-size: 100% 100%;
  }
  .roundB {
    background: url('../../assets/images/img1/round3.png') no-repeat;
    background-size: 100% 100%;
  }
  .width4-5 {
    width: 4.5vw;
    height: 9.3vh;
  }
  .width5 {
    width: 5vw;
    height: 10.5vh;
  }
  .width5-7 {
    width: 5.7vw;
    height: 11.9vh;
  }
  .width7 {
    width: 7vw;
    height: 14.5vh;
  }
  .round1 {
    top: 5.7vw;
    left: 6.2vw;
  }
  .round2 {
    top: 2.5vw;
    left: 17vw;
  }
  .round3 {
    top: 8vw;
    left: 14.5vw;
  }
  .round4 {
    top: 5vw;
    left: 25.3vw;
  }
  .round5 {
    top: 13.3vw;
    left: 4.5vw;
  }
  .round6 {
    top: 17vw;
    left: 13.8vw;
  }
  .round7 {
    top: 19.5vw;
    left: 21.1vw;
  }
  .round8 {
    top: 12vw;
    left: 22.7vw;
  }
  .round9 {
    top: 5.2vw;
    left: 33vw;
  }
  .round10 {
    top: 11.5vw;
    left: 35vw;
  }
  .round11 {
    top: 19.5vw;
    left: 32vw;
  }
  .round12 {
    top: 11.5vw;
    left: 10.3vw;
  }
  .round13 {
    top: 10vw;
    left: 29vw;
  }
  .round14 {
    top: 17.5vw;
    left: 38vw;
  }
  .activePicColor {
    color: white !important;
    background: url('../../assets/images/img1/round5.png') no-repeat;
    background-size: 100% 100%;
  }
}
.topTab {
  display: flex;
  background-image: url('../../assets/images/img1/page2.png');
  width: 95vw;
  height: 11vh;
  justify-content: space-around;
  background-size: 95vw 11vh;
  margin-left: 2.4vw;
}

.topTab div {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.topTab div span {
  font-weight: bold;
  font-size: 1.1vw;
  // background-image: linear-gradient(to top, #2BB3FF, #46E2FF);
  // background-clip: text;
  // color: transparent;
  color: #2bb3ff;
}

.topTab div img {
  width: 3vw;
  height: 3vw;
}
.echart-box {
  display: flex;
  justify-content: center;
  & > span {
    display: inline-block;
    width: 1vw;
    margin-top: 3.5vw;
  }
}
// 审核中心
.cascader {
  position: relative;

  .title {
    position: absolute;
  }

  /deep/.el-cascader-panel {
    position: absolute;
    top: 5vw;
    left: 1vw;
    z-index: 9999;
    background-color: white;
  }

  /deep/.el-cascader-menu:nth-child(2) {
    position: absolute;
    left: 180px;
    background: white;
    top: 0vw;
    border-radius: 2px;
  }

  .cascader-icon {
    position: absolute;
    color: white;
    top: 4vw;
    font-size: 2vw;
    z-index: 100000;
  }
}
/deep/ .el-cascader-menu__wrap,
/deep/ .el-scrollbar__wrap {
  margin: 0 !important;
  overflow: hidden !important;
  // height: 100%;
}
/deep/ .el-cascader-menu {
  height: 180px !important;
  // height: 216px !important;
}

.echart-table {
  border: 1px solid #0a3d6e;
  width: 90%;
  margin-left: 5%;
  margin-top: 5px;
}
.el-table {
  color: white !important;
}

::v-deep .el-table tbody tr:hover > td {
  background-color: #0a3d6e !important;
}
::v-deep .el-table,
::v-deep .el-table__expanded-cell,
::v-deep .el-table tr {
  background-color: transparent !important;
}
/deep/ .el-table td.el-table__cell,
/deep/ .el-table th.el-table__cell.is-leaf {
  border-bottom: transparent !important;
}

.el-table::before {
  //去除底部白线
  left: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 0px !important;
}
.amount-to {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin-left: 5%;
  margin-bottom: 10px;
  color: red;
  span {
    display: inline-block;
    padding: 0 15px;
  }
}
</style>
